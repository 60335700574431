(function($) {

	$.fn.exampleFunction = function(params) {

		// this = jQuery kolekce objektů. 
		// Teoreticky je možné, ež jich bude víc než jeden - záleží na tom, jak pak plugin použiješ.

		this.addClass('hello');

		// Tímto způsobem lze projet všechny elementy v kolekci a zpracovat každý z nich samostatně:
		this.each(function() {

			if ($(this).width() > 100) {
				$(this).addClass('wide-element');
			}

		})

		// Argumenty předávané při volání pluginu se předají a lze je používat.
		if (params && params.className) {
			this.addClass(params.className);
		}

		// správně by každý jquery plugin měl vracet this, aby bylo možné řetězit jejich volání za sebe
		return this;

	}

	setTimeout(function(){

		$(".good").addClass("hide-popup");

	}, 2000);

	$(".info__wrapper").find(".icon-close").click(function () {

		$(".info__wrapper").addClass("hide-info");

	});

})(jQuery);